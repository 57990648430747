



































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BoxAnexos extends Vue {
    // public files = [];
    public progress = 0;

    // public removeFile( index: number ) {
    //     this.files.splice(index, 1);
    //     this.files = [...this.files];
    // }

    get sendingFile() {
        return this.$store.state.composer.enviandoAnexo;
    }

    get files() {
        return this.$store.state.composer.anexos;
    }

    set files( file ) {
        console.log(file);
        this.$store.commit('SET_ANEXO', file);
    }

    public removeFile( index: number ) {
        this.$store.commit('CLEAR_ANEXO');
    }

    public getIcon( file: File ) {
        const mime = file.type.split('/');
        let type = '';
        if ( mime.length === 2 ) {
            if ( mime[0] === 'application' ) {
                if ( mime[1] === 'x-zip-compressed' ) {
                    type = 'folder-zip';
                }
                if ( mime[1] === 'pdf' ) {
                    type = 'pdf-box';
                }
            } else {
                type = mime[0];
                if ( type === 'document' ) {
                    type = 'file-document';
                }
            }
        }

        if ( type === '' ) {
            type = 'file-document';
        }

        return type;
    }

}
