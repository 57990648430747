import axios from 'axios';

export default class Api {

    public static getMessages( data: {tipo: string, id: number} ) {
        return Api.instance.get<ResponseMensagens>(`mensagens.php`, { ...Api.defaultData , params: data })
            .then((response) => response.data);
    }

    public static sendMessage(mensagem: {}) {
        return Api.instance.post<ResponseMensagem>(`mensagens.php`, mensagem)
            .then((response) => response.data);
    }

    public static sendAnexo(arquivo: File) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const formData = new FormData();
        formData.append('anexo', arquivo);

        return Api.instance.post<ResponseAnexo>(`anexos.php`, formData, config)
            .then((response) => response.data);
    }

    public static markAsRead(data: {tipo: string} ) {
        return Api.instance.patch<GenericResponse>(`mensagens.php`, {...data, action: 'read', id: 0})
            .then((response) => response.data);
    }

    private static defaultData = {
    };

    public static get getUrl() {
        return process.env.VUE_APP_API_URL;
    }

    public static get getAnexoUrl() {
        return process.env.VUE_APP_ANEXO_URL;
    }

    private static instance = axios.create({
        baseURL: Api.getUrl,
        withCredentials: true,
    });

}
