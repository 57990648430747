










































import { Component, Ref, Vue } from 'vue-property-decorator';
import { det } from 'detergent';
import BoxAnexos from '@/components/BoxAnexos.vue';

@Component({
    components: { BoxAnexos },
})
export default class Composer extends Vue {
    @Ref('textarea') public readonly textarea!: HTMLTextAreaElement;

    public boxAnexos = false;
    public message = '';

    get temAnexo() {
        return this.$store.state.composer.anexos !== null &&
               this.$store.state.composer.anexos !== undefined &&
               this.$store.state.composer.anexos.size > 0 ;
    }

    get empty() {
        return this.format(this.message).trim().length === 0;
    }

    public format( text: string ) {
        text = det(text).res;
        return text;
    }

    public update( { currentTarget }: InputEvent ) {
        if (currentTarget !== null) {
            this.message = this.format((currentTarget as HTMLElement).innerHTML);
        }
    }

    public paste( $event: ClipboardEvent ) {
        $event.preventDefault();
        if ($event.clipboardData !== null) {
            let textPlain = $event.clipboardData.getData('text/plain');
            textPlain = this.format(textPlain);
            window.document.execCommand('insertText', false, textPlain);
        }
    }

    public keydown( $event: KeyboardEvent ) {
        if (!$event.shiftKey) {
            if ($event.key === 'Enter') {
                this.send();
                $event.preventDefault();
            }
        }
    }

    public send() {
        if (!this.empty) {
            const msg = this.message.trim();
            this.message = '';
            this.textarea.innerHTML = '';
            this.$emit('messageprepared');
            this.$store.dispatch('SEND_MESSAGE', msg)
            .then(() => {
                this.$emit('messagesent');
            });
        }
    }
}
