








































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import anchorme from 'anchorme';
import { saveAs } from 'file-saver';
import Api from '../plugins/api';

moment.locale('pt-br');

@Component
export default class Mensagem extends Vue {
    @Prop() private readonly data!: ChatMensagem;

    get usuario() {
        return this.$store.state.usuarioSelecionado;
    }

    get time() {
        // if ( this.data.status === -1 ) {
        //     return 'Erro, falha ao enviar sua mensagem';
        // }
        return moment.unix(this.data.time).calendar(undefined, {
            sameDay:  '[Hoje] - HH:mm',
            nextDay:  '[Amanhã] - HH:mm',
            nextWeek: 'dddd',
            lastDay:  '[Ontem] - HH:mm',
            lastWeek: 'dddd - HH:mm',
            sameElse: 'DD/MM - HH:mm',
        });
    }

    get icon() {
        switch (this.data.status) {
            case -1:
                // return 'mdi-alert red--text text--darken-3';
            case 0:
                return 'mdi-clock-outline';
            default:
            case 1:
                return 'mdi-check';
            case 2:
                return 'mdi-check text-info';
        }
    }

    get usuarioIsTarget() {
        return this.data.id_origem === 0;
    }

    get usuarioIsSender() {
        return this.data.id_destino === 0;
    }

    get message() {
        return anchorme(this.data.message, {
            attributes: [
                { name: 'target', value: 'blank' },
            ],
        });
    }

    get hasAnexos() {
        return this.data.anexos !== undefined &&
               this.data.anexos !== null;
    }

    get anexoType() {
        if ( this.data.anexos !== undefined &&
             this.data.anexos !== null &&
             this.data.anexos.url !== null ) {
            if ( this.data.anexos.url.endsWith('png')  ||
                 this.data.anexos.url.endsWith('jpg')  ||
                 this.data.anexos.url.endsWith('jpeg') ||
                 this.data.anexos.url.endsWith('gif') ) {
                 return 'image';
            }
            return 'download';
        }
        return 'none';
    }

    get anexoUrl() {
        if ( this.data.anexos !== undefined && this.data.anexos !== null ) {
            return Api.getAnexoUrl + this.data.anexos.url;
        }
        return '';
    }

    public downloadFile() {
        if ( this.data.anexos !== undefined && this.data.anexos !== null ) {
            saveAs(this.anexoUrl, this.data.anexos.nome);
        }
    }
}
