








import { Component, Vue } from 'vue-property-decorator';
import store from './store';
import Conversa from '@/views/Conversa.vue';

@Component({
    store,
    components: { Conversa },
})
export default class App extends Vue {
    public tipo = (this.$parent.$el.attributes.getNamedItem('tipo') as Attr).value;

    public created() {
        this.$store.commit('SET_TIPO', this.tipo);
    }

}
