import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/plugins/api';
// @ts-ignore
import sound from '@/assets/notification.mp3';

Vue.use(Vuex);

const notification = new Audio(sound);
notification.loop = false;

export default new Vuex.Store({
    state:     {
        tipo:             '',
        mensagens:        [] as ChatMensagem[],
        loadingMensagens: true,
        unread:           0,
        composer: {
            anexos: undefined as File|undefined,
            enviandoAnexo: false,
        },
    },
    mutations: {
        SET_TIPO:       ( state, payload ) => {
            state.tipo = payload;
        },
        SET_ENVIANDO_ANEXO: (state, payload) => {
            state.composer.enviandoAnexo = payload;
        },
        SET_ANEXO: (state, payload) => {
            state.composer.anexos = payload;
        },
        CLEAR_ANEXO: (state) => {
            state.composer.anexos = undefined;
        },
        ADD_MESSAGE: (state, payload) => {
            if (state.mensagens === undefined ) {
                state.mensagens = [];
            }
            state.mensagens.push(payload);
        },
        SET_MESSAGES:   ( state, payload ) => {
            state.loadingMensagens = false;
            if (payload === undefined ) {
                state.mensagens = [];
            } else {
                state.mensagens = payload;
            }
        },
        SET_UNREAD:   ( state, payload ) => {
            if (payload > state.unread) {
                notification.play();
            }
            state.unread = payload;
        },
        UPDATE_MESSAGE: ( state, payload ) => {
            Vue.set(state.mensagens, payload.index, payload.value);
        },
    },
    getters:   {
        mensagens: (state) => {
            const result = [] as ChatMensagem[];
            const map = new Map();
            if ( state.mensagens !== undefined ) {
                for (const item of state.mensagens) {
                    if (!map.has(item.id)) {
                        map.set(item.id, true);    // set any value to Map
                        result.push(item);
                    }
                }
            }
            return result;
        },
    },
    actions:   {
        GET_MESSAGES: ( { state, commit } ) => {
            return new Promise( (resolve) => {
                Api.getMessages({ tipo: state.tipo, id: 0 })
                    .then(( data ) => {
                        commit('SET_MESSAGES', data.messages);
                        commit('SET_UNREAD', data.unread);
                        resolve();
                    });
            });
        },
        MARK_READ: ( { state, commit } ) => {
            return new Promise( (resolve) => {
                Api.markAsRead({ tipo: state.tipo})
                    .then( () => {
                        commit('SET_UNREAD', 0);
                        resolve();
                    });
            });
        },
        SEND_MESSAGE: ( { state, commit }, payload ) => {
            return new Promise( (resolve) => {
                const timestamp = Math.floor(new Date().getTime() / 1000);
                const message: ChatMensagem = {
                    id:         timestamp,
                    id_destino: 0,
                    tipo_chat:  state.tipo,
                    message:    payload,
                    status:     0,
                    time:       timestamp,
                };

                const addMessage = () => {
                    commit('ADD_MESSAGE', message);
                    Api.sendMessage(message)
                        .then(( data ) => {
                            const id = state.mensagens.findIndex(( msg ) => msg.id === timestamp);
                            let newMessage: ChatMensagem;
                            if (data.success) {
                                newMessage = data.mensagem;
                            } else {
                                newMessage = {
                                    id:         timestamp,
                                    id_destino: 0,
                                    tipo_chat:  state.tipo,
                                    message:    payload,
                                    status:     -1,
                                    time:       timestamp,
                                };
                            }
                            commit('UPDATE_MESSAGE', { index: id, value: newMessage });
                            resolve();
                        });
                };

                if ( state.composer.anexos instanceof File ) {
                    commit('SET_ENVIANDO_ANEXO', true);
                    Api.sendAnexo(state.composer.anexos)
                    .then((data) => {
                        commit('CLEAR_ANEXO');
                        commit('SET_ENVIANDO_ANEXO', false);
                        message.anexos = data.anexo;
                        addMessage();
                    });
                } else {
                    addMessage();
                }

            });
        },
    },
});
