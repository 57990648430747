








































// tslint:disable:no-console
import {Vue, Component, Ref} from 'vue-property-decorator';
import Composer from '@/components/Composer.vue';
import Mensagem from '@/components/Mensagem.vue';
import * as firebase from 'firebase/app';
// import 'firebase/analytics';
import 'firebase/messaging';
import Api from '../plugins/api';


@Component({
    components: {
        Composer,
        Mensagem,
    },
})
export default class Conversa extends Vue {
    public askNotification = false;
    public active = false;
    public interval = 0;
    @Ref('scroll') private readonly scroll!: HTMLElement;

    get tipoChat() {
        return this.$store.state.tipo;
    }

    get counter() {
        return this.$store.state.unread;
    }

    get mensagens() {
        return this.$store.getters.mensagens;
    }

    get loadingMensagens() {
        return this.$store.state.loadingMensagens;
    }

    public mounted() {
        this.$store.dispatch('GET_MESSAGES').then(() => {
            this.scrollBottom();
        });
        this.scheduleUpdate();

        if ('Notification' in window) {
            this.askNotification = Notification.permission === 'default';
            if ( Notification.permission === 'granted' ) {
                this.fixIndexDB();
            }
        }
    }

    public stopUpdate() {
        clearInterval(this.interval);
    }

    public scheduleUpdate() {
        this.interval = setTimeout( () => {
            this.$store.dispatch('GET_MESSAGES').then(() => {
                this.scheduleUpdate();
            });
        }, 3000);
    }

    public scrollBottom() {
        if (this.scroll !== undefined) {
            const height = this.scroll.scrollHeight + 100;
            this.scroll.scroll(0, height);
        }
    }

    public postMessageSent() {
        setTimeout(() => {
            this.scrollBottom();
        });
        this.scheduleUpdate();
    }

    public activateChat() {
        this.active = true;
        setTimeout(() => {
            this.scrollBottom();
        });
        this.$store.dispatch('MARK_READ');
    }

    public askPermission() {
        if ('Notification' in window) {
            Notification.requestPermission().then( (permission) => {
                this.askNotification = false;
                if ( permission === 'granted' ) {
                    this.saveToken();
                }
            });
        }
    }

    public fixIndexDB() {
        const dbName = 'fcm_token_details_db';
        const fcm = indexedDB.open(dbName);
        fcm.onsuccess = (event) => {
            let version;
            if ( event.srcElement !== undefined ) {
                version = (event.srcElement as IDBOpenDBRequest).result.version; // chrome
            } else {
                version = (event.target as IDBOpenDBRequest).result.version; // firefox
            }

            if ( version !== 3) {
                indexedDB.deleteDatabase(dbName);
            }
            this.saveToken();
        };
        fcm.onblocked = () => {
            this.saveToken();
        };
        fcm.onerror = () => {
            this.saveToken();
        };
    }

    public saveToken() {

        const firebaseConfig = {
            apiKey:            'AIzaSyDZWlTEUQTgRg5TXmw-D1V7FsYT8pi7hCU',
            authDomain:        'chat-pennachi-gfh9b.firebaseapp.com',
            databaseURL:       'https://chat-pennachi-gfh9b.firebaseio.com',
            projectId:         'chat-pennachi-gfh9b',
            storageBucket:     'chat-pennachi-gfh9b.appspot.com',
            messagingSenderId: '993626802606',
            appId:             '1:993626802606:web:fc272d3b18a657ca18203f',
        };
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
        const messaging = firebase.messaging();
        messaging.usePublicVapidKey('BBig9u7iJuh06CxONLxtdYuekW5gJvohYD_wKq1n585A4HbjjYU_zODmd4QHrQ0bH3VQ43dvIm7DLPXQQ77ahNg');
        navigator.serviceWorker.getRegistration()
            .then(( reg ) => {
                if (reg !== undefined) {
                    messaging.useServiceWorker(reg);

                    messaging.getToken()
                        .then(( token: string | null ) => {
                            console.log(token);
                            if (token !== undefined && token !== null) {
                                fetch(`${Api.getUrl}/firebase.php`, {
                                    method:      'POST',
                                    credentials: 'same-origin',
                                    body:        `token=${token}&tipo=${this.tipoChat}`,
                                }).then(( data ) => data.json())
                                    .then(( data ) => {
                                        console.log(data);
                                    });
                            }
                        })
                        .catch(( error ) => {
                            console.error(error);
                        });

                    messaging.onMessage( (data) => {
                        console.log(data);
                    });
                }
            });
    }
}
